<template>
  <div class="about main-container">
    <p class="about-item">RAYCHON+ GmbH</p>
    <div class="about-item">
        <span>Fronäcker 8</span><br/>
         <span>71263 Weil der Stadt-Deutschland</span>
    </div>
    <p class="about-item">Telefon +49 (0) 7033 475 0813</p>
    <p class="about-item">Email: info@raychonplus.com</p>
    <p class="about-item">Geschäftsführer: Jiesheng Zhu</p>
    <p class="about-item">Umsatzsteuerident-Nr.: DE335459002</p>
    <p class="about-item">Handelsregister-Nr.: Stuttgart HRB 775089</p>
  </div>
</template>

<style scoped  lang="scss">
.page-body {
    background-image: linear-gradient(to right, #0e0e0e, #5b5757);
}
    .about {
        position: relative;
        background: url(../../assets/bg2.png) center;
        color: #000;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 30px 0 5px 7px;
        height: 40vh;
    }
    .about-item {
        font-size: 16px;
        padding-left: 3%;
        font-weight: bold;
    }
    .about-bg {
        width: 100%;
    }
</style>
